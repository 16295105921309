import axios from "axios";
import * as moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axiosConfig from "./axiosConfig";

export default function Home() {
  const [timesheets, setTimeSheets] = useState([]);
  const [show, setShow] = useState(false);
  const [pin, setPin] = useState("");
  const [currentType, setCurrentType] = useState("");
  const [activeStaff, setActiveStaff] = useState("");
  const [btnSave, setbtnSave] = useState("");
  const navigateTo = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = (staff, type, typeText) => {
    setShow(true);
    setbtnSave(typeText);
    setCurrentType(type);
    setActiveStaff(staff.staffId);
  };
  const handleCheckinCheckout = () => {
    axiosConfig
      .post(
        "/v1/timesheets",
        {
          staffId: activeStaff,
          pin: pin,
          type: currentType,
        },
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      )
      .then((response) => {
        setShow(false);
        setbtnSave("");
        setCurrentType("");
        setActiveStaff("");
        axiosConfig
          .get("/v1/timesheets", {
            headers: { Authorization: localStorage.getItem("token") },
          })
          .then((response) => {
            setTimeSheets(response.data);
          });
      })
      .catch((e) => {
        alert("Xảy ra lỗi, có thể bạn nhập sai mã PIN");
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) navigateTo("/login");
    axiosConfig
      .get("/v1/timesheets", {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        setTimeSheets(response.data);
      });
    const interval = setInterval(() => {
      axiosConfig
        .get("/v1/timesheets", {
          headers: { Authorization: localStorage.getItem("token") },
        })
        .then((response) => {
          setTimeSheets(response.data);
        });
    }, 1000 * 60 * 60 * 5);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="row align-items-start p-5">
      <h1>{moment().format("DD-MM-YYYY")}</h1>
      {timesheets.length > 0 &&
        timesheets.map((item) => {
          return (
            <div className="col">
              <div className="card" style={{ marginRight: "18rem" }}>
                <div className="card-body">
                  <h5 className="card-title">{item.staffName}</h5>
                  <p className="card-text">
                    Giờ vào:{" "}
                    {item.checkin
                      ? moment(item.checkin).format("DD-MM-YYYY HH:mm")
                      : ""}
                  </p>
                  <p className="card-text">
                    Giờ ra:{" "}
                    {item.checkout
                      ? moment(item.checkout).format("DD-MM-YYYY HH:mm")
                      : ""}
                  </p>
                  {!(item.checkin && item.checkout) ? (
                    <div>
                      {!item.checkin && (
                        <Button
                          variant="primary"
                          onClick={() =>
                            handleShow(item, "checkin", "Check In")
                          }
                          style={{ marginRight: 20 }}
                        >
                          Check In
                        </Button>
                      )}

                      {item.checkin ? (
                        <Button
                          variant="danger"
                          onClick={() =>
                            handleShow(item, "checkout", "Check Out")
                          }
                        >
                          Check Out
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          );
        })}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Điền mã Pin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mã Pin</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => setPin(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleCheckinCheckout}>
            {btnSave}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
